<template>
  <div class="pa-vega-measurement">
    <div class="vx-row mb-10">
      <div class="mt-10 vx-col pa-vega-measurement__heading">
        <h3 class="pa-vega-measurement__heading__title">{{ $t('views.vegaMeasurement.adjustingVegaScore') }}</h3>
      </div>
    </div>

    <div v-if="activeUserInfo && company" class="mt-5 flex flex-wrap items-center mb-base">
      <div class="flex items-center mb-5">
        <span class="mr-2 pa-vega-measurement__title-inner">{{ $t('views.vegaMeasurement.targetGroup') }}</span>

        <vx-tooltip class="flex items-center" style="width: 17px" position="top" :title="$t('views.vegaMeasurement.info')" :text="''">
          <img width="17" height="17" :src="infoImg" style="display: inline-block" />
        </vx-tooltip>
      </div>

      <vs-row v-for="(item, index) in scoreList" :key="index">
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <vs-checkbox hide-details class="ma-0 pt-0 pb-1 checkbox-main" :class="{ 'checkbox-main--lighten': behindIsDefaultScoreItemIndex > index, 'checkbox-main--active-disabled': HAS_ACCESS && item.isDefault }" v-model="item.isDefault" :disabled="!HAS_ACCESS || item.isDefault" @change="scoreUpdated(item)">
              {{ item.text }}
            </vs-checkbox>
          </div>
        </div>
      </vs-row>
    </div>

    <vs-progress v-else indeterminate color="primary"></vs-progress>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const duration = require('dayjs/plugin/duration')
dayjs.extend(utc)
dayjs.extend(duration)

export default {
  name: 'VegaMeasurement',

  data() {
    return {
      fetchedVegaPopupThreshhold: false,
      infoImg: require('@/assets/images/elements/info.svg'),
      scoreList: [
        {
          text: 'On Fire (83% - 100% VEGA Score)',
          value: 'onfire',
          score: '0.83',
          isDefault: false
        },
        {
          text: 'Hot (67% - 83% VEGA Score)',
          value: 'hot',
          score: '0.67',
          isDefault: true
        },
        {
          text: 'Warm (50% - 67% VEGA Score)',
          value: 'warm',
          score: '0.5',
          isDefault: false
        },
        {
          text: 'Mild (33% - 57% VEGA Score)',
          value: 'mild',
          score: '0.33',
          isDefault: false
        },
        {
          text: 'Cold (17% - 33% VEGA Score)',
          value: 'cold',
          score: '0.17',
          isDefault: false
        },
        {
          text: 'Frozen (1% - 17% VEGA Score)',
          value: 'frozen',
          score: '0.01',
          isDefault: false
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      company: 'company',
      activeUserInfo: 'activeUser',
    }),

    HAS_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('vega') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },

    behindIsDefaultScoreItemIndex() {
      return this.scoreList.findIndex((item) => Boolean(item.isDefault))
    }
  },

  watch: {
    'company'() {
      if (this.company && this.company.vegaPopupThreshhold && !this.fetchedVegaPopupThreshhold) {
        this.getVegaPopupThreshhold()
      }
    }
  },

  mounted() {
    if (this.company && this.company.vegaPopupThreshhold && !this.fetchedVegaPopupThreshhold) {
      this.getVegaPopupThreshhold()
    }
  },

  methods: {
    getVegaPopupThreshhold() {
      this.fetchedVegaPopupThreshhold = true

      const isSelectedItem = this.scoreList.findIndex((item) => item.value === this.company.vegaPopupThreshhold) > -1

      if (!isSelectedItem) {
        return
      }

      this.scoreList.forEach((item) => {
        if (item.value === this.company.vegaPopupThreshhold) {
          item.isDefault = true

          return
        }

        item.isDefault = false
      })
    },

    async scoreUpdated(item) {
      if (!item.isDefault) {
        return
      }

      await this.$vs.loading()

      try {
        const copyItem = JSON.parse(JSON.stringify(item))

        this.scoreList = this.scoreList.map((scoreListItem) => {
          if (scoreListItem.value === copyItem.value) {
            return scoreListItem
          }

          return {
            ...scoreListItem,
            isDefault: false
          }
        })

        await this.$db.collection('company').doc(this.company.id).set({ vegaPopupThreshhold: copyItem.value}, { merge: true })
      } catch (error) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t('info.somethingWentWrong'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }

      await this.$vs.loading.close()
    }
  }
}
</script>

<style lang="scss">
.checkbox-main {
  font-size: 18px;

  .vs-checkbox {
    margin-right: 20px;
    width: 22px;
    height: 22px;
  }

  &--lighten {
    .vs-checkbox {
      background: rgb(149, 196, 214) !important;
      border: 2px solid rgb(149, 196, 214) !important;
      transform: rotate(0deg) !important;

      .vs-checkbox--check {
        transform: translate(0px) !important;
      }

      .vs-checkbox--icon {
        opacity: 1 !important;
        color: rgb(255, 255, 255) !important;
        transform: translate(0px) !important;
      }
    }
  }

  &--active-disabled {
    .vs-checkbox {
      opacity: 1 !important;
      background: rgba(var(--vs-primary),1) !important;
      border: 2px solid rgba(var(--vs-primary),1) !important;
    }
  }
}

.pa-vega-measurement {
  &__heading {
    margin: 0 auto;
    max-width: 760px;
    text-align: center;
    color: #262629;

    &__title {
      color: #262629;
      font-size: 30px;
      font-weight: 600;
      line-height: 38px;
    }
  }

  &__title-inner {
    color: rgb(18, 89, 141);
    font-size: 16px;
    line-height: 1.25;
    font-weight: 600;
  }
}
</style>
