var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pa-vega-measurement" },
    [
      _c("div", { staticClass: "vx-row mb-10" }, [
        _c(
          "div",
          { staticClass: "mt-10 vx-col pa-vega-measurement__heading" },
          [
            _c("h3", { staticClass: "pa-vega-measurement__heading__title" }, [
              _vm._v(
                _vm._s(_vm.$t("views.vegaMeasurement.adjustingVegaScore"))
              ),
            ]),
          ]
        ),
      ]),
      _vm.activeUserInfo && _vm.company
        ? _c(
            "div",
            { staticClass: "mt-5 flex flex-wrap items-center mb-base" },
            [
              _c(
                "div",
                { staticClass: "flex items-center mb-5" },
                [
                  _c(
                    "span",
                    { staticClass: "mr-2 pa-vega-measurement__title-inner" },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("views.vegaMeasurement.targetGroup"))
                      ),
                    ]
                  ),
                  _c(
                    "vx-tooltip",
                    {
                      staticClass: "flex items-center",
                      staticStyle: { width: "17px" },
                      attrs: {
                        position: "top",
                        title: _vm.$t("views.vegaMeasurement.info"),
                        text: "",
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: { display: "inline-block" },
                        attrs: { width: "17", height: "17", src: _vm.infoImg },
                      }),
                    ]
                  ),
                ],
                1
              ),
              _vm._l(_vm.scoreList, function (item, index) {
                return _c("vs-row", { key: index }, [
                  _c("div", { staticClass: "vx-row mb-3" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c(
                          "vs-checkbox",
                          {
                            staticClass: "ma-0 pt-0 pb-1 checkbox-main",
                            class: {
                              "checkbox-main--lighten":
                                _vm.behindIsDefaultScoreItemIndex > index,
                              "checkbox-main--active-disabled":
                                _vm.HAS_ACCESS && item.isDefault,
                            },
                            attrs: {
                              "hide-details": "",
                              disabled: !_vm.HAS_ACCESS || item.isDefault,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.scoreUpdated(item)
                              },
                            },
                            model: {
                              value: item.isDefault,
                              callback: function ($$v) {
                                _vm.$set(item, "isDefault", $$v)
                              },
                              expression: "item.isDefault",
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.text) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ])
              }),
            ],
            2
          )
        : _c("vs-progress", { attrs: { indeterminate: "", color: "primary" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }